@import url(https://fonts.googleapis.com/css?family=Raleway:300,400);
:root {
  /* avatar colors */
  /* light  rgb(217,230,233) */
  /* darker rgb(236,240,241) */
  /* outer  rgb(230, 237, 239) */
  --avatar-l: rgb(217, 230, 233);
  --avatar-d: rgb(236, 240, 241);
  --avatar-o: rgb(230, 237, 239);
  --color1: rgba(0, 24, 33, 1);
  --color2: rgba(18, 69, 89, 1);
  --color3: rgba(89, 131, 146, 1);
  --color4: rgba(174, 195, 176, 1);
  --color5: rgba(239, 246, 224, 1);
  --a-col: rgb(59, 59, 59);
  --bg-col: var(--color3);
  --project-bg: var(--color3);
  --project-lang-bg: var(--color2);
  --proj-a: var(--avatar-o);
  --nav-col: var(--color2);
  --navbtn-col: var(--avatar-d);
  --navbtn-bg-col: ;
  --about-bg: var(--color2);
}
input,
textarea {
  border: solid 0.5px rgba(174, 195, 176, 1);
  border: solid 0.5px var(--color4);
  color: rgb(230, 237, 239);
  color: var(--avatar-o);

  background: rgba(18, 69, 89, 1);

  background: var(--color2);
}
.submit-btn {
  font-size: 1em;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 30px;
  width: 70px;
  transition: background-color 0.4s linear;
}
.submit-btn:hover {
  background-color: rgb(217, 230, 233);
  background-color: var(--avatar-l);
  color: rgb(59, 59, 59);
  color: var(--a-col);
  transition: background-color 0.4s linear;
}
* {
  box-sizing: border-box;
  font-family: Raleway;
}
html,
body {
  height: 100%;
  margin: 0;
  background: -webkit-linear-gradient(
    var(--color2),
    var(--color3) 35%,
    var(--color2)
  );
}

hr {
  margin-top: 0;
}

ul {
  display: inline-block;
  padding: 0;
}
label {
  display: block;
  margin: 15px;
}
textarea {
  padding-top: 10px;
  width: 250px;
  height: 100px;
}
input {
  text-align: center;
  width: 200px;
}
a {
  color: rgb(59, 59, 59);
  color: var(--a-col);
}

#contact-form {
  justify-content: center;
}
.nav-bar {
  font-size: x-large;
  padding-top: 30px;
  padding-bottom: 10px;

  text-align: center;
  display: block;
  position: relative;
  width: auto;
  background-color: rgba(18, 69, 89, 1);
  background-color: var(--nav-col);
}

.nav-bar a {
  transition: underline 2s linear;
}
.nav-bar li {
  color: rgb(236, 240, 241);
  color: var(--navbtn-col);
  background-color: ;
  background-color: var(--navbtn-bg-col);
  padding: 4px;
  padding-left: 15px;
  padding-right: 15px;

  border-top: 1px solid rgba(89, 131, 146, 1);

  border-top: 1px solid var(--color3);
  border-bottom: 1px solid rgba(89, 131, 146, 1);
  border-bottom: 1px solid var(--color3);

  font-size: x-large;
  display: inline-flex;
  list-style: none;
  transition: background-color 0.4s linear;
}
.nav-bar li:hover {
  background-color: rgb(217, 230, 233);
  background-color: var(--avatar-l);
  color: rgb(59, 59, 59);
  color: var(--a-col);
  transition: background-color 0.4s linear;
}
.nav-bar img {
  display: block;
  margin: auto;
  height: 100px;
  border-radius: 50px;
}
.nav-bar svg {
  margin: 1px;
}
.nav-bar p {
  text-align: end;
  font-weight: bold;
  font-size: 0.5em;
  margin: 0;
  transform: translate(0, +10px);
}
.icons a {
  padding: 2px;
  color: rgb(230, 237, 239);
  color: var(--avatar-o);
}
.icons img {
  display: inline-block;
  margin-left: 3px;
  transform: translate(0, -2.5px);
  height: 22px;
  border-radius: 0;
}
.icons a:hover,
img:hover {
  opacity: 0.75;
  transition: opacity 0.4s;
}
.content {
  /* border: var(--avatar-l) 1px solid; */
  color: rgb(230, 237, 239);
  color: var(--avatar-o);
  text-align: center;
  display: block;
  margin-top: 20px;
  left: 10px;
  right: 10px;
  position: absolute;
  border-radius: 15px;
  padding: 5px;
  padding-bottom: 25px;
}
.content h1 {
  text-shadow: 1px 1px rgb(27, 27, 27);
}
.content iframe {
  text-align: center;
  border: none;
  width: 100%;
  height: 750px;
  /* TODO 
    auto set height
  */
}
.language h3 {
  margin-bottom: 15px;
}
.projects-by-lang {
  position: relative;
  display: inline-block;
  background-color: rgba(18, 69, 89, 1);
  background-color: var(--project-lang-bg);
  margin: 15px;
  border-radius: 7px;
  border: solid 1px rgba(174, 195, 176, 1);
  border: solid 1px var(--color4);
  background: rgba(18, 69, 89, 1);
  background: var(--about-bg);
}
.content-projects {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.project h2 {
  margin-top: 0;
}
.project {
  width: 220px;

  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  color: rgb(59, 59, 59);
  color: var(--a-col);
  font-weight: bolder;
  font-size: 1.05em;
  background: rgb(217, 230, 233);
  background: var(--avatar-l);
}
.projects-by-lang button {
  color: rgb(59, 59, 59);
  color: var(--a-col);
  font-weight: bold;
  margin: 0;
  cursor: pointer;
  position: absolute;
  bottom: 8px;
  right: 18%;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid black;
  border-radius: 4px;
  background-color: none;
  background: none;
  -webkit-animation: pulse 8s infinite;
          animation: pulse 8s infinite;
  transition: background 0.4s;
}
@-webkit-keyframes pulse {
  70% {
    color: rgb(217, 230, 233);
    color: var(--avatar-l);
    border-color: rgba(174, 195, 176, 1);
    border-color: var(--color4);
    background: rgba(18, 69, 89, 1);
    background: var(--color2);
  }
}
@keyframes pulse {
  70% {
    color: rgb(217, 230, 233);
    color: var(--avatar-l);
    border-color: rgba(174, 195, 176, 1);
    border-color: var(--color4);
    background: rgba(18, 69, 89, 1);
    background: var(--color2);
  }
}
.projects-by-lang button:hover {
  background-color: rgb(255, 255, 255);
  transition: background-color 0.4s;
}
.project a:hover {
  background-color: rgb(255, 255, 255);
  transition: background-color 0.4s;
}
.project a {
  border-radius: 4px;
  display: inline-block;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;

  margin-top: 12px;
  margin-bottom: 8px;
  color: rgb(59, 59, 59);
  color: var(--a-col);
  border: 1px solid black;
  padding-top: 3px;
  font-size: large;
  transition: background-color 0.4s;
}

h4 {
  margin: 0;
  padding: 5px;
}
.about {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.about-text {
  min-width: 200px;
  transform: translateX(55px);
  display: inline-block;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 25px;
  max-height: 350px;
  border-radius: 7px;
  border: solid 1px rgba(174, 195, 176, 1);
  border: solid 1px var(--color4);
  background: rgba(18, 69, 89, 1);
  background: var(--about-bg);
  overflow: hidden;
}
.about-text h1 {
  height: 25px;
  margin-left: 0px;
  margin-right: 15px;
  margin-top: 15px;
}
.about-text ul {
  color: rgb(59, 59, 59);
  color: var(--a-col);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  padding-top: 25px;
  padding-bottom: 15px;
  padding-right: 15px;
  padding-left: 25%;
  margin: 0px;
  height: 100%;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  background: rgb(217, 230, 233);
  background: var(--avatar-l);
}

.about-text li {
  font-size: 1.7em;
  text-align: left;
  padding-top: 25px;
}
.about-icons {
  transform: translateX(55px);
  display: inline-block;
  margin-left: 55px;
  margin-right: 15px;
  margin-top: 15px;
  border-radius: 7px;
  border: solid 1px rgba(174, 195, 176, 1);
  border: solid 1px var(--color4);
  background: rgba(18, 69, 89, 1);
  background: var(--about-bg);
  width: 35%;
  max-width: 410px;
  overflow: hidden;
}
.about-icons ul{
  display: inline-block;
  text-align: left;
}
.about-icons li{
  list-style: none;
}
.about-icons h2 {
  height: 25px;

}
.about-icons p {
  padding-top: 0px;
  margin: 10px;
  
  display: block;
}
.about-icons:hover {
  cursor: pointer;
}
.about-icons-clicker-text {
  position: fixed;
  top: 0;
  font-size: 1em;
  right: 0;
}
.about-img {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  background: rgb(217, 230, 233);
  background: var(--avatar-l);
  height: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.about-img img {
  height: 60px;
  margin: 10px;
}
img:hover {
  opacity: 1;
}
.email-text {
  font-size: 2em;
  color: rgb(236, 240, 241);
  color: var(--avatar-d);
}

@media screen and (max-width: 700px) {
  .about-text {
    transform: translateX(0);
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 35px;
    min-width: 250px;
  }
  .about-icons {
    margin-left: 5px;
    margin-right: 5px;
    transform: translateX(0);
    min-width: 277px;
    text-align: center;
  }
  .about-img img {
    height: 50px;
  }
}

